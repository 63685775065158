import { PayloadAction } from '@reduxjs/toolkit'
import { PropertyContentApi, ReservationApi } from 'Types/api.types'
import { ReservationState } from 'Src/types/reservation-state.types'
import { parseApiResponse } from './reservation.utils'
import guestsReducer from './guests/guests.reducer'
import timesReducer from './times/times.reducer'
import productsReducer from './products/products.reducer'
import cleaningMidStayReducer from './cleaning-mid-stay/cleaning-mid-stay.reducers'
import othersReducer from './others/others.reducer'
import ReservationActionTypes from './reservation.types'
import { Reducer } from 'redux'

const INITIAL_STATE = {
	initialReservationData: null,
	initialPropertyContent: null,
	reservation: null,
	mainGuest: {},
	mainGuestCustomer: {},
	isMainGuestEmailVisible: true,
	mainGuestAddress: {},
	mainGuestDocument: null,
	mainGuestCreditCard: {},
	mainGuestNewCreditCard: {},
	creditCards: [],
	additionalGuests: [],
	availableTimes: {},
	arrivalTimeData: {},
	departureTimeData: {},
	productsState: {
		productsAdditionalData: [],
		orderedProducts: [],
		selectedProductKeys: [],
		products: [],
		productsConsumptionDates: [],
		productsAmount: [],
	},
	travelReasons: [],
	isBreakfastCampaignActive: false,
	isCityTaxRequired: false,
	shouldCaptureBusinessLead: false,

	propertyContent: {
		checkInImageUrl: null,
	},
}

export interface Payload {
	data: object
	toUpdateInitialData: boolean
	reservation: ReservationApi,
	propertyContent?: PropertyContentApi,
}

const reservationReducer = (
	// @ts-ignore
	state: ReservationState = INITIAL_STATE,
	action: PayloadAction<Payload>,
): ReservationState => {
	switch (action.type) {
		case ReservationActionTypes.RESET_RESERVATION_STATE:
			if (!state.initialReservationData) return state

			return {
				...state,
				...parseApiResponse(state.initialReservationData, state.initialPropertyContent),
				// persist isCityTaxRequired
				isCityTaxRequired: state.isCityTaxRequired,
			}

		case ReservationActionTypes.UPDATE_RESERVATION: {
			const { data, toUpdateInitialData } = action.payload
			const newState: ReservationState = {
				...state,
				// :todo - typescript
				// @ts-ignore
				reservation: {
					...state.reservation,
					...data,
				},
			}

			if (toUpdateInitialData && newState.initialReservationData) {
				newState.initialReservationData = {
					...newState.initialReservationData,
					...data,
				}
			}

			return newState
		}

		// GET RESERVATION
		case ReservationActionTypes.GET_RESERVATION_START:
			return {
				...state,
			}

		case ReservationActionTypes.GET_RESERVATION_SUCCESS: {
			const { reservation, propertyContent } = action.payload

			return {
				...state,
				...parseApiResponse(reservation, propertyContent),
				initialReservationData: reservation,
				initialPropertyContent: propertyContent,
				shouldCaptureBusinessLead: reservation.shouldCaptureBusinessLead,
			}
		}

		// PATCH RESERVATION
		case ReservationActionTypes.PATCH_RESERVATION_START:
			return {
				...state,
			}

		case ReservationActionTypes.PATCH_RESERVATION_SUCCESS: {
			const { reservation } = action.payload
			return {
				...state,
				...parseApiResponse(reservation),
				initialReservationData: reservation,
				// persist isCityTaxRequired (missing item in PATCH api)
				isCityTaxRequired: state.isCityTaxRequired,
				// persist shouldCaptureBusinessLead (missing item in PATCH api)
				shouldCaptureBusinessLead: state.shouldCaptureBusinessLead,
			}
		}

		default:
			return state
	}
}

const reservationCombinedReducers = (
	state: ReservationState,
	action: PayloadAction<Payload>,
) => {
	let newState = state

	newState = reservationReducer(newState, action)
	newState = guestsReducer(newState, action)
	newState = timesReducer(newState, action)
	// :todo typescript
	// @ts-ignore
	newState = productsReducer(newState, action)
	newState = cleaningMidStayReducer(newState, action)
	newState = othersReducer(newState, action)

	return { ...newState }
}

export default reservationCombinedReducers as Reducer<ReservationState>
