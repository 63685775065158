import React from 'react'
import type { ComponentType, FC } from 'react'
import { Card, CardContent, Content, PageViewStyled } from './PageView.styled'
import PageFooter from './PageFooter'

interface Props {
  readonly Header?: ComponentType;
  readonly HeroSection?: ComponentType;
  readonly FooterSection?: ComponentType;
}

const PageView : FC<Props> = ({
	Header,
	HeroSection,
	FooterSection,
	children,
	...props
}) => (
	<PageViewStyled>
		{Header ? <Header /> : null}

		<Content withHeader={!!Header} {...props}>
			{HeroSection ? <HeroSection /> : null}
			<Card>
				<CardContent>
					{children}
				</CardContent>
				{FooterSection ? <FooterSection /> : null}
			</Card>

			<PageFooter />
		</Content>
	</PageViewStyled>
)

export default PageView
