import React, { FC } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { COLORS } from '@/globals/global.styles'
import SustainabilityImage from 'Assets/images/sustainability.svg'
import { selectIsWhiteLabel } from 'Src/redux/reservation/reservation.selectors'

const PageFooterStyled = styled.div`
	margin-top: -16px;
	padding: 38px 0;
	text-align: center;
	background-color: ${COLORS.background.secondary};
`

const Link = styled.a`
	font-weight: 500;
	font-size: 14px;
	line-height: 1.5em;
	color: ${COLORS.text.link};
	text-decoration: none;
`

const PageFooter : FC = () => {
	const isWhiteLabel = useSelector(selectIsWhiteLabel)

	if (isWhiteLabel)	{
		return null
	}

	return (
		<PageFooterStyled>
			<Link
				href="https://bobw.co/sustainability?lng=en-GB"
				target="_blank"
			>
				<img
					src={SustainabilityImage}
					alt=""
				/>
			</Link>
		</PageFooterStyled>
	)
}

export default PageFooter
