import config from 'Globals/constants'

import { PropertyContentApi, ReservationApi } from 'Src/types/api.types'
import { PropertyContentModel, ReservationState } from 'Src/types/reservation-state.types'

import {
	getLatestUpdated,
	isBlacklistedEmail,
	parseAdditionalGuests,
} from './guests/guests.utils'
import { parseArrivalTimeData, parseDepartureTimeData } from './times/times.utils'
import { parseProducts } from './products/products.utils'
import { campaignTypes, getPropertyCampaign } from '@/globals/hardcoded-data/campaigns'
import { transformCreditCard, transformCreditCards } from '@/api/transforms/credit-card'
import { getValidCreditCard } from '@/utils/payment'

const { COUNTRIES } = config

function parsePropertyContent(propertyContent?: PropertyContentApi) : PropertyContentModel {
	if (propertyContent === undefined) {
		return {
			checkInImageUrl: null,
		}
	}

	return {
		checkInImageUrl: propertyContent.checkinImageUrl,
	}
}

const parseApiResponse = (reservation: ReservationApi, propertyContent?: PropertyContentApi): ReservationState => {
	const {
		id: reservationId,
		slug,
		status: reservationStatus,
		checkInDate,
		checkOutDate,
		adultCount,
		childrenCount,
		travelReasons,
		propertyUnit,
		requestedPropertyUnitCategory,
		requestedPropertyUnitCategoryId,
		confirmationNumber,
		channelConfirmationNumber,
		property,
		propertyKeys,
		reservationGuests,
		orderItems,
		services,
		creditCard,
		creditCards,
		$frontAppUserHash,
		channelManager,
		travelCompanyId,
		isCityTaxRequired,
		customer: {
			id: mainGuestCustomerId,
			firstName,
			lastName,
			email,
			phone,
			phoneCountryCallingCode,
			phoneCountryCode,
			birthDate,
			birthCountryCode,
			gender,
			nationalityCode,
			customerDocuments,
			customerAddress,
			customerSignature,
			customerSignatures,
			userId,
			languageCode,
			data,
			options,
		},
	} = reservation

	const birthProvince = data?.italyBirthCommune ?? undefined

	const mainGuest = reservationGuests
		&& reservationGuests.find((guest) => guest.customer.id === mainGuestCustomerId)
	const document = getLatestUpdated(customerDocuments)
	const {
		id: documentId,
		type: documentType,
		number: documentNumber,
		issuingCountryCode: documentIssueCountry,
		data: documentData,
	} = document || {}
	const {
		addressLine1,
		postalCode,
		city,
		countryCode,
		countrySubdivisionCode,
	} = customerAddress || {}
	const { visitedCountryCode, destinationCity, destinationCountryCode } = mainGuest ?? {}
	const propertyId = reservation?.property?.id ?? ''
	const startDate = reservation?.startDate
		? new Date(reservation?.startDate)
		: null
	const endDate = reservation?.endDate
		? new Date(reservation?.endDate)
		: null

	const breakfastCampaign = getPropertyCampaign(campaignTypes.complimentaryBreakfast, propertyId)
	const isBreakfastCampaignActive = breakfastCampaign?.isCampaignAvailable({
		stayStartDate: startDate,
		stayEndDate: endDate,
		orderItems,
		services,
	}) ?? false

	const isMarketingConsentGiven = options?.includes('send-marketing-emails') ?? false

	const updatedInitialCustomer: ReservationApi['customer'] = {
		...reservation.customer,
		isMarketingConsentGiven,
	}

	const initialReservationWithConsent: ReservationApi = {
		...reservation,
		customer: updatedInitialCustomer,
	}

	const transformedCreditCards = transformCreditCards(creditCards)
	const transformedCreditCard = creditCard ? transformCreditCard(creditCard) : null
	const additionalGuests = reservationGuests?.filter((guest) => guest.customer.id !== mainGuestCustomerId)
	const parsedAdditionalGuests = parseAdditionalGuests(additionalGuests, reservation.customer, property.countryCode)

	// @ts-ignore
	return {
		propertyContent: parsePropertyContent(propertyContent),
		initialReservationData: initialReservationWithConsent,
		reservation: {
			id: reservationId!,
			slug,
			status: reservationStatus,
			checkInDate,
			checkOutDate,
			adultCount,
			childrenCount,
			propertyUnit,
			requestedPropertyUnitCategory,
			requestedPropertyUnitCategoryId,
			productConfirmationNumber: confirmationNumber || '-',
			bookingNumber: channelConfirmationNumber || confirmationNumber || '-',
			channel: channelManager,
			travelCompanyId,
			property: {
				...property,
				country: COUNTRIES.find(({ code }) => code === property.countryCode)?.name || '',
			},
			propertyKeys,
			$frontAppUserHash,
		},
		mainGuest: {
			visitedCountryCode,
			destinationCity,
			destinationCountryCode,
		},
		mainGuestCustomer: {
			id: mainGuestCustomerId,
			firstName,
			lastName,
			email: isBlacklistedEmail(email) ? null : email,
			phone,
			gender,
			phoneCountryCallingCode,
			phoneCountryCode,
			birthDate,
			birthCountryCode,
			birthProvince,
			nationalityCode,
			customerSignature,
			customerSignatures,
			customerDocuments,
			userId,
			customerAddress,
			languageCode,
			isMarketingConsentGiven,
			options,
		},
		isMainGuestEmailVisible: isBlacklistedEmail(email) || !email || !email.includes('@'),
		mainGuestAddress: {
			addressLine1,
			postalCode,
			city,
			countryCode,
			countrySubdivisionCode,
		},
		mainGuestDocument: document
			? {
				id: documentId,
				type: documentType,
				number: documentNumber,
				supportNumber: documentData?.spainIdentityDocumentSupportNumber,
				issueCountry: documentIssueCountry,
				issueProvince: documentData?.italyIssuingCommune ?? undefined,
			}
			: null,
		mainGuestCreditCard: getValidCreditCard(transformedCreditCard, transformedCreditCards),
		mainGuestNewCreditCard: null,
		creditCards: transformedCreditCards,
		additionalGuests: parsedAdditionalGuests,
		arrivalTimeData: parseArrivalTimeData(reservation),
		departureTimeData: parseDepartureTimeData(reservation),
		// @ts-ignore
		productsState: parseProducts({
			orderItems,
			services,
			propertyKey: property.key,
			isBreakfastCampaignActive,
		}),
		travelReasons: travelReasons ? [...travelReasons] : null,
		isBreakfastCampaignActive,
		isCityTaxRequired,
	}
}

export {
	parseApiResponse,
}
